import React from "react"
import styled from "styled-components"

import ProfileCard from "../profileCard"
import mylan from "../../assets/mylan-v2.jpg"
import caroline from "../../assets/caroline-v2.jpg"
import amy from "../../assets/amy-v2.jpg"
import louise from "../../assets/louise-v2.jpg"
// import ricki from "../../assets/ricki-v2.jpg"

const GridContainer = styled.div`
  display: grid;
  grid-gap: ${props => props.theme.spacing(6)}px;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;

  @media (min-width: ${props => props.theme.breakpoints.values.sm}px) {
    grid-template-columns: 1fr 1fr;
  }
`

const Team = () => {
  const team = [
    {
      name: "Mylan Vu",
      title: "Founder and PR Consultant",
      avatarSrc: mylan,
    },
    {
      name: "Caroline La Rose",
      title: "Senior PR Director",
      avatarSrc: caroline,
    },
    {
      name: "Amy Teutenberg",
      title: "PR Director",
      avatarSrc: amy,
    },
    {
      name: "Louise Jaques",
      title: "Content Director",
      avatarSrc: louise,
    },
    // {
    //   name: "Ricki Bui",
    //   title: "PR Consultant and Media Relations Specialist",
    //   avatarSrc: ricki,
    // },
  ]

  return (
    <GridContainer>
      {team.map(member => (
        <div
          data-sal="flip-up"
          data-sal-delay="300"
          data-sal-duration="800"
          data-sal-easing="ease-out-cubic"
          key={member.name}
        >
          <ProfileCard {...member} />
        </div>
      ))}
    </GridContainer>
  )
}

export default Team
