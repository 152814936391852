import React from "react"
import styled from "styled-components"

import ciLogo from "../../assets/cultural-intelligence-logo.jpg"
import mihLogo from "../../assets/make-it-happen-hq.jpg"
import facLogo from "../../assets/fac-logo.png"

const HeroGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 120px));
  grid-gap: ${props => props.theme.spacing(4)}px;
  padding: ${props => props.theme.spacing(2)}px;
  align-items: center;
`

const Img = styled.img`
  object-fit: contain;
  width: 100%;
  background-color: white;
`
const itemData = [
  {
    img: facLogo,
    title: "First Australians Capital",
  },
  {
    img: ciLogo,
    title: "Cultural Intelligence",
  },
  {
    img: mihLogo,
    title: "Make It Happen HQ",
  },
]

export default function SingleLineImageList() {
  return (
    <HeroGridContainer>
      {itemData.map(item => (
        <div key={item.img}>
          <Img src={item.img} alt={item.title} />
        </div>
      ))}
    </HeroGridContainer>
  )
}
