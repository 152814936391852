import React from "react"
import styled from "styled-components"

import { useTheme } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import MuiLink from "@material-ui/core/Link"

import Layout from "../components/layout"
import Team from "../components/team"
import Partners from "../components/partners"
import Head from "../components/head"
import workTimeSvg from "../assets/work_time.svg"

const HideOnScrollMargin = styled.main`
  margin-top: 56px;

  @media (min-width: ${props => props.theme.breakpoints.values.sm}px) {
    margin-top: 64px;
  }
`

const HeroContent = styled.div`
  padding: ${props => props.theme.spacing(7, 0, 4)};

  @media (min-width: ${props => props.theme.breakpoints.values.sm}px) {
    padding: ${props => props.theme.spacing(10, 0, 7)};
  }
`

const HeroGridContainer = styled.div`
  display: grid;
  grid-gap: ${props => props.theme.spacing(6)}px;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;

  @media (min-width: ${props => props.theme.breakpoints.values.sm}px) {
    grid-template-columns: 1fr 1fr;
  }
`

const HeadshotWrap = styled.div`
  text-align: center;

  .img {
    width: 100%;
    overflow: hidden;
  }
`

const AboutPage = () => {
  const theme = useTheme()

  return (
    <Layout>
      <Head title="About" />
      <HideOnScrollMargin>
        <HeroContent>
          <Container maxWidth="lg">
            <HeroGridContainer>
              <div>
                <div
                  data-sal="slide-right"
                  data-sal-delay="100"
                  data-sal-duration="800"
                  data-sal-easing="ease-out-cubic"
                >
                  <Typography
                    component="h1"
                    variant="h3"
                    align="left"
                    color="textPrimary"
                    gutterBottom
                    style={{ marginBottom: theme.spacing(4) }}
                  >
                    Vu Consulting is a boutique PR and communications team of
                    senior experts driven by impact.
                  </Typography>
                  <Typography
                    variant="body1"
                    align="left"
                    color="textSecondary"
                    paragraph
                  >
                    Each consultant brings years of experience working with
                    technology, corporate, NFP, social enterprise, and B2B
                    organisations to build brand awareness by cutting through
                    the increasingly complex and noisy media landscape.
                  </Typography>
                  <Typography
                    variant="body1"
                    align="left"
                    color="textSecondary"
                    paragraph
                    gutterBottom
                    style={{ marginBottom: theme.spacing(3) }}
                  >
                    The team is passionate about sharing stories that help
                    progress our society and communities, highlighting
                    innovations that would otherwise be overlooked, and giving a
                    voice to the voiceless.
                  </Typography>
                  <Typography
                    variant="body1"
                    align="left"
                    color="textSecondary"
                    paragraph
                    gutterBottom
                    style={{ marginBottom: theme.spacing(3) }}
                  >
                    We aim to spend 50% of our time working with grassroots
                    NFPs, charities and social enterprises on pro bono projects.
                    Our remaining time is spent working with corporates that are
                    driving change and innovation. We also offer discounted
                    services, determined on a case by case basis, for corporates
                    with diverse founder or leadership teams.
                  </Typography>
                  <Typography
                    variant="body1"
                    align="left"
                    color="textSecondary"
                    paragraph
                    gutterBottom
                    style={{ marginBottom: theme.spacing(3) }}
                  >
                    The consultancy's unique business model aims to disrupt the
                    way agencies have traditionally operated, offering new ways
                    for consultants to deliver purpose-driven PR services while
                    working with the autonomy to live purpose-driven lives.
                  </Typography>
                  <Typography
                    variant="body1"
                    align="left"
                    color="textSecondary"
                    paragraph
                    gutterBottom
                    style={{ marginBottom: theme.spacing(3) }}
                  >
                    The Vu Consulting team are proud founding members of the{" "}
                    <MuiLink
                      href="https://morevoicesmorerepresentation.com/"
                      rel="noopener noreferrer"
                      target="blank"
                      aria-label="More Voices, More Representation at International Women’s Day"
                    >
                      More Voices More Representation at International Women’s
                      Day (IWD) campaign
                    </MuiLink>
                    , which aims to champion underrepresented, underserved,
                    minoritised and racialised women and non-binary voices
                    across the various events, panels, and media coverage
                    surrounding IWD.
                  </Typography>
                </div>
              </div>
              <div
                data-sal="zoom-in"
                data-sal-delay="100"
                data-sal-duration="800"
                data-sal-easing="ease-out-cubic"
              >
                <HeadshotWrap>
                  <img
                    src={workTimeSvg}
                    alt="Mylan cut through communications"
                    className="img"
                  />
                </HeadshotWrap>
              </div>
            </HeroGridContainer>
            <div
              css={`
                margin-top: ${props => props.theme.spacing(6)}px;
              `}
            >
              <Typography
                component="h2"
                variant="h4"
                align="left"
                color="textPrimary"
                gutterBottom
                style={{ marginBottom: theme.spacing(4) }}
              >
                Meet the Team
              </Typography>
              <Team />
            </div>
            <div
              css={`
                margin-top: ${props => props.theme.spacing(6)}px;
              `}
            >
              <Typography
                component="h2"
                variant="h4"
                align="left"
                color="textPrimary"
                gutterBottom
                style={{ marginBottom: theme.spacing(4) }}
              >
                Our for-purpose partners
              </Typography>
              <Partners />
            </div>
          </Container>
        </HeroContent>
      </HideOnScrollMargin>
    </Layout>
  )
}

export default AboutPage
