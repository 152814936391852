import React from "react"
import styled from "styled-components"
import Avatar from "@material-ui/core/Avatar"

import Typography from "@material-ui/core/Typography"

const Wrap = styled.div`
  display: grid;
  grid-template-columns: ${props => props.theme.spacing(15)}px 1fr;
  grid-gap: ${props => props.theme.spacing(4)}px;
  padding: ${props => props.theme.spacing(2)}px;
  align-items: center;
`

const CustomAvatar = styled(Avatar)`
  height: ${props => props.theme.spacing(15)}px;
  width: ${props => props.theme.spacing(15)}px;
`

const ProfileCard = ({ name, title, avatarSrc }) => {
  return (
    <Wrap>
      <div>
        <CustomAvatar alt={name} src={avatarSrc} />
      </div>

      <div>
        <Typography variant="h5" component="p">
          {name}
        </Typography>
        <Typography
          gutterBottom
          variant="subtitle2"
          component="p"
          color="textSecondary"
        >
          {title}
        </Typography>
      </div>
    </Wrap>
  )
}

export default ProfileCard
